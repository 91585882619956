import React, { Component } from 'react'
import { Button, Spin, Image, Form, Input, Checkbox, Upload, Card, Typography, Row, Col, Empty, notification, Divider, Radio, InputNumber, Select } from 'antd'
import { apiCall } from '../../utils/http'
import { WEBSITE_NAME } from "../../utils/process";
import { cookie_list, createCookie, getCookie } from '../../utils/cookie'
import moment from 'moment'
import CVList from '../cvlist'
import { withRouter } from 'react-router-dom'
import { content, getOS } from '../../constants/content'
import { notificationContent } from '../../constants/notifications'
import OTPModal from '../../components/modal'
import { UploadOutlined } from "@ant-design/icons"
import { googleAnalyticsRegister, googleAnalyticsApplyOnSite, googleAnalyticsApplyOffSite, facebookAnalyticsRegister, facebookAnalyticsSubmit } from '../../utils/events'
import SearchJobDataHandling from '../../utils/SearchJobDataHandling';
import CommonFunctionalities from '../../utils/CommonFunctionalities';

let apiKey = process.env.REACT_APP_API_KEY
const { Text, Title, Link } = Typography
const { TextArea } = Input;

let os = getOS()
//NOTIFICATION POPUP
function notificationPop(type, header, message) {
    notification.destroy()
    notification[type]({
        message: header,
        description: message
    })
}
class JobDetail extends Component {
    constructor() {
        super();
        this.child = React.createRef();
        this.componentMounted = false;
        this.state = {
            loader: true,
            showModal: false,
            token: getCookie(cookie_list.TOKEN) || '',
            jobDetail: [],
            CvId: '',
            jobId: '',
            email: '',
            cvFile: null,
            jobExist: true,
            pageNumber: null,
            agreement: false,
            fileUploaded: false,
            cvListCount: null,
            isCV: getCookie(cookie_list.CV) ? getCookie(cookie_list.CV) : '',
            postCode: null,
            showNoOfYears: false,
            utmCampaign: getCookie(cookie_list.UTMCAMPAIGN) || null,
            ipaddress: getCookie(cookie_list.IPADDRESS) || null,
            fileList: [],
        }
    }
    formRef = React.createRef();
    componentDidMount() {
        if (!this.componentMounted) {
            this.componentMounted = true;
            this.setState({ pageNumber: this.props?.location?.state?.pageNumber })
            const ID = new URLSearchParams(window.location.search)?.get('id') || ''
            if (ID) {
                this.setState({ jobId: ID }, () => this.fetchJobDetail());
            }
            const utm_campaign = new URLSearchParams(window.location.search)?.get('utm_campaign') || null
            if (utm_campaign) {
                createCookie(cookie_list.UTMCAMPAIGN, utm_campaign, 4320, '/');
                this.setState({ utmCampaign: utm_campaign });
            }
            if (getCookie(cookie_list.CV) === "yes" || getCookie(cookie_list.CV) === "no") {
                setTimeout(() => {
                    document.getElementById('registrationSubmitForm').scrollIntoView();
                    createCookie(cookie_list.CV, '', 4320, '/');
                }, 2000);
            } else {
                window.scrollTo(0, 0);
            }
        }
    }
    fetchJobDetail = async () => {
        // userId url ipAddress userAgent userLanguage campaign
        let url = 'personalisedjobs/job?apiKey=' + apiKey + '&jobId=' + this.state.jobId + '&source=' + os;
        const userAgent = navigator.userAgent;
        const userLanguage = navigator.language;


        url += userAgent ? `&userAgent=${userAgent}` : '';
        url += userLanguage ? `&userLanguage=${userLanguage}` : '';
        url += this.state.utmCampaign ? `&campaign=${this.state.utmCampaign}` : '';
        const ipResponse = await fetch('https://api.ipify.org?format=json');
        const ipData = await ipResponse.json();
        url += ipData.ip ? `&ipAddress=${ipData.ip}` : '';
        // .then(response => response.json())
        // .then(data => url += data.ip ? `&ipAddress=${data.ip}` : '')
        // .catch(error => console.log(error))
        await apiCall(url, 'GET').then((res) => {
            createCookie(cookie_list.IPADDRESS, ipData.ip, 4320, '/');
            this.setState({ ipAddress: ipData.ip });
            if (res.status === 200) {
                this.setState({ jobDetail: res.data, jobExist: true, clickPost: res.data.ClickPost })
            }
            else {
                this.setState({ jobExist: false })
            }
            this.setState({ loader: false })
        }).catch(() => {
            notificationPop(notificationContent.FAILED, content.noJobLabel)
            this.setState({ loader: false })
        })

    }
    errorNotificationNocv = () => {
        notificationPop('error', notificationContent.FAILED, 'CV is required')
        this.setState({ loader: false })
    }
    btnSubmit = (values) => {
        if (values.isCV) {
            this.setState({ isCV: values.isCV });
        }
        this.setState({
            email: values.email ? values.email : '',
            cvFile: values.upload ? values : null,
            loader: true,
            postCode: values.postcode ? values.postcode : null
        }, () => {
            if (this.state.token === '') {
                this.registerUser(values)
            }
            else {
                if (this.state.fileUploaded && this.state.CvId === '') {
                    if (values.upload?.fileList.length > 0) {
                        this.uploadCv(values)
                    } else {
                        this.errorNotificationNocv();
                    }
                }
                else if (this.state.CvId) {
                    this.submitApplication()
                } else if ((this.state.isCV === 'yes' || this.state.isCV === "") && this.state.CvId === "") {
                    this.errorNotificationNocv();
                }
                else {
                    this.submitApplication()
                }
            }
        })
    }
    //GET USER TOKEN
    fetchUserToken = async (email) => {
        let form_data = {
            Email: email,
            ApiKey: apiKey
        }

        await apiCall('users/otp/request', 'POST', form_data).then((res) => {
            if (res?.status === 200) {
                this.setState({ showModal: true })
            } else {
                notificationPop(notificationContent.FAILED, res?.data?.message)
            }
            this.setState({ loader: false })
        })
    }
    //REGISTER NEW USER
    registerUser = async (values) => {
        let form_data = {
            Name: `${values.firstname} ${values.lastname}`,
            Email: values.email,
            Source: os,
            OperatingSystem: 'windows',
            SendDailyEmails: values.agreement,
            Telephone: values.phonenumber,
            SalaryRange: 'From1To10000',
            PostCode: values.postcode,
            UtmCampaign: '',
            UtmMedium: '',
            UtmSource: '',
            ApiKey: apiKey
        }

        await apiCall('users', 'POST', form_data).then((res) => {
            if (res?.status === 200) {
                notificationPop('success', notificationContent.SUCCESS, res?.data?.message)
                this.fetchUserToken(values.email)
                //SEND REGISTER EVENT TO GOOGLE TAB MANAGER
                googleAnalyticsRegister()
                facebookAnalyticsRegister()
                // start: Script for nursesjobs
                const script = document.createElement('script');
                script.text = `
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({'event': 'registration'});
                `;
                document.body.appendChild(script);
                // end: Script for nursesjobs
            } else {
                notificationPop('error', notificationContent.FAILED, res?.data?.message)
            }
            this.setState({ loader: false })
        })
    }

    uploadCv = async (values) => {
        let formData = new FormData();
        formData.append("file", values.upload.file.originFileObj);
        formData.append("FileName", values.upload.file.name);
        formData.append("Source", os);
        formData.append("TalentIncCvReviewConsent", true);
        formData.append("AllowAgentContacts", true);
        formData.append("ApiKey", apiKey);
        if (this.getEnvironment() === 'nursesjobs') {
            formData.append("NursesPin", values.nursesPin);
            formData.append("NursesBand", values.band);
        }
        if (this.getEnvironment() === 'carejobs') {
            formData.append("HasCriminalRecord", values.criminalConvictions === 'no' ? false : true);
        }
        if (this.getEnvironment() === 'carejobs' || this.getEnvironment() === 'nursesjobs') {
            formData.append("HasDrivingLicense", values.licenceCar && values.licenceCar === 'no' ? false : true);
            formData.append("HasOwnVehicle", values.licenceCar && values.licenceCar === 'no' ? false : true);
        }
        formData.append("RightToWork", values.workStatus);

        await apiCall('users/cv', 'POST', formData, false, true).then((res) => {
            if (res?.status === 200) {
                notificationPop('success', notificationContent.SUCCESS, 'CV uploaded successfully.')
                this.setState({ cvListCount: null, fileUploaded: false, fileList: [] }, () => {
                    //refresh cv list
                    this.child.current.fetchCvList();
                    this.formRef.current.resetFields();
                })
                // this.setState({fileUploaded: false, fileList: []});
                // this.submitApplication(res.data.Id)
            } else {
                notificationPop('error', notificationContent.FAILED, 'Sorry, there was a problem with your CV. Upload a new CV to complete the application.')
            }
            this.setState({ loader: false })
        })
    }
    //GET TOKEN RESPONSE AND SET COOKIES
    modalResponse = async (response) => {
        if (response?.status === 200) {
            //SET COOKIES
            createCookie(cookie_list.TOKEN, response?.data?.access_token, 4320, '/')
            createCookie(cookie_list.EMAIL, this.state.email, 4320, '/')
            createCookie(cookie_list.FIRSTNAME, response?.data?.FirstName, 4320, '/')
            createCookie(cookie_list.EXPIRY, moment().add(180, 'days').format('YYYY-MM-DD HH:mm:ss'), 4320, '/')
            createCookie(cookie_list.DISTANCE, '5', 4320, '/')
            createCookie(cookie_list.CV, this.state.isCV || '', 4320, '/')
            createCookie(cookie_list.POSTCODE, this.state.postCode || '', 4320, '/')
            let isRestoredLocation = await SearchJobDataHandling.restoreLocationData();
            if (isRestoredLocation) {
                createCookie(cookie_list.LOCATION, getCookie(cookie_list.LOCATION) || '', 4320, '/')
                createCookie(cookie_list.LONGITUDE, getCookie(cookie_list.LONGITUDE) || '', 4320, '/')
                createCookie(cookie_list.LATITUDE, getCookie(cookie_list.LATITUDE) || '', 4320, '/')
                createCookie(cookie_list.LOCATIONTYPE, getCookie(cookie_list.LOCATIONTYPE) || '', 4320, '/')
                createCookie(cookie_list.COUNTRY, getCookie(cookie_list.COUNTRY) || '', 4320, '/')
                //SET THE OCCUPATIONS IN THE DB 
                await SearchJobDataHandling.checkUserData()
                //CLOSE MODAL AND REDIRECT TO JOB SEARCH
                this.setState({ showModal: false }, () => {
                    //Commented out the below code as the flow of the registration and Upload/Apply process has changed.
                    // The upload CV & send email will be one on button click  
                    // if (this.state.cvFile) {
                    //     this.uploadCv(this.state.cvFile)
                    // }
                    // else {
                    //     this.sendEmail()
                    //     notificationPop('success', notificationContent.SUCCESS, "Complete this application on the company's website. We have emailed you a link to the application form as well.")
                    // }

                    //Token is saved in the state variable for the form to disappear and the proper action for the job apply is shown
                    //the reload is avoided as it changes the forcus of the job apply flow
                    // this.setState({token: getCookie(cookie_list.TOKEN)})
                    if (this.state.token === '') {
                        window.location.reload()
                    }
                })
            }
        } else {
            notificationPop(notificationContent.FAILED, response?.data?.message)
        }
    }

    submitApplication = async (cvId) => {
        let formValues = this.formRef.current.getFieldsValue();
        let form_data = {
            JobId: this.state.jobId,
            Referrer: "CareJobs",
            Campaign: getCookie(cookie_list.UTMCAMPAIGN) || null,
            IpAddress: getCookie(cookie_list.IPADDRESS) || null,
            Source: os,
            AgreeBuyerEmailAlerts: true,
            AgreeBuyerCvSearch: true,
            Question1Response: true,
            Question2Response: true,
            Question3Response: true,
            Multi: false,
            ApiKey: apiKey,
            PostCode: this.state.postCode ? this.state.postCode : getCookie(cookie_list.POSTCODE),
        }
        if (this.state.isCV === "no") {
            //no of year experience
            if (formValues.experience) {
                let noYears = 0;
                if (formValues.experience === 'yes') {
                    noYears = formValues.noYearsExperience
                }
                form_data = { ...form_data, IndustryExperienceYears: noYears }
            }
            form_data = {
                ...form_data,
                HasDrivingLicense: formValues.licence === 'yes' ? true : false,
                RightToWork: formValues.workStatus,
                CandidateSummary: formValues.aboutYou,
            }
            if (this.getEnvironment() === 'nursesjobs') {
                form_data = {
                    ...form_data,
                    NursesPin: formValues.nursesPin,
                    NursesBand: formValues.band,
                }
            }
            if (this.getEnvironment() === 'carejobs') {
                form_data = {
                    ...form_data,
                    Address1: formValues.addressLine1,
                    Address2: formValues.addressLine2,
                    City: formValues.city
                }
            }
        } else {
            form_data = { ...form_data, UseCvId: cvId ? cvId : this.state.CvId }
        }
        let url = 'jobshortlists/apply';
        if (this.state.isCV === 'no') {
            url = 'jobshortlists/apply-without-cv';
        }
        await apiCall(url, 'POST', form_data, false, true).then((res) => {
            if (res?.status === 200) {
                googleAnalyticsApplyOnSite()
                facebookAnalyticsSubmit()
                notificationPop('success', notificationContent.SUCCESS, 'Thank you for applying')
                this.setState({ loader: false })
                if (this.state.clickPost === false) {
                    this.props.history.push(
                        '/job-search',
                        { jobID: this.state.jobId, similarJobs: true }
                    )
                }
                createCookie(cookie_list.CV, '', 4320, '/')
            } else {
                notificationPop('error', notificationContent.FAILED, res?.data?.message)
            }
            this.setState({ loader: false })
        })
    }

    setCvId = (id) => {
        this.setState({ CvId: id, fileList: [], fileUploaded: false })
    }

    redirectToLogin = () => {
        this.props.history.push(
            '/login',
            { jobID: this.state.jobId }
        )
    }

    goToJobSearch = () => {
        this.props.history.push(
            '/job-search',
            { pageNumber: this.state.pageNumber, jobID: this.state.jobId, similarJobs: false }
        )
    }

    sendEmail = async () => {
        googleAnalyticsApplyOffSite()
        let form_data = {
            "Id": this.state.jobId,
            "ApiKey": apiKey
        }
        await apiCall('users/emailapplylink', 'POST', form_data, false, true).then((res) => {
            if (res?.status === 200) {
                notificationPop('success', notificationContent.SUCCESS, 'The link to apply for job is emailed to you')

            } else {
                notificationPop('error', notificationContent.FAILED, res?.data?.message)
            }
            this.setState({ loader: false })
        })
    }

    checkFileType = (file) => {
        let extension = file.name.substring(file.name.lastIndexOf('.') + 1);
        const fileSize = file.size / 1024 / 1024; // in MiB

        if (extension !== "pdf" && extension !== "docx" && extension !== "doc" && extension !== "rtf") {
            notificationPop('warning', notificationContent.FAILED, "Your file must be a .doc, .pdf, .docx, or .rtf.")
            return Upload.LIST_IGNORE;
        }
        else if (fileSize > 1) {
            notificationPop('warning', notificationContent.FAILED, "Max. file size: 1 MB.")
            return Upload.LIST_IGNORE;
        }
        else {
            return true
        }
    }

    fileChange = (file) => {

        if (file) {
            let uploadList = file.fileList.length > 0 ? file.fileList[file.fileList.length - 1] : null;
            if (uploadList) { uploadList.status = "done"; }
            this.setState({ fileUploaded: true, fileList: uploadList ? [uploadList] : [] })
            if (this.state.CvId !== '') {
                this.child.current.resetCVSelect();
                this.setState({ CvId: '' })
            };
        } else {
            this.setState({ fileUploaded: false })
        }
    }
    SetCVCount = (count = null) => {
        this.setState({ cvListCount: count });
    }
    uploadImage = async (options) => {
        const { onSuccess, onError, file, onProgress } = options;
        this.setState({ loader: true })
        let formData = new FormData();
        formData.append("file", file);
        formData.append("FileName", file.name);
        formData.append("Source", os);
        formData.append("TalentIncCvReviewConsent", true);
        formData.append("AllowAgentContacts", true);
        formData.append("ApiKey", apiKey);
        try {
            let headers = { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + getCookie(cookie_list.TOKEN) }
            await apiCall('users/cv', 'POST', formData, false, true)
                .then((res) => {
                    onSuccess("Ok");
                    if (res?.status === 200) {
                        notificationPop('success', notificationContent.SUCCESS, 'CV uploaded successfully.')
                        this.setState({ cvListCount: null, fileUploaded: false }, () => {
                            //refresh cv list
                            this.child.current.fetchCvList();
                        })
                        // this.submitApplication(res.data.Id)
                    } else {
                        notificationPop('error', notificationContent.FAILED, 'Sorry, there was a problem with your CV. Upload a new CV to complete the application.')
                    }
                    this.setState({ loader: false })
                })
        } catch (err) {
            onError({ err });
        }
        this.setState({ loader: false })
    };
    getEnvironment = () => {
        // return 'nursesjobs';
        if (process.env.REACT_APP_ENV === 'carejobs' || process.env.REACT_APP_ENV === 'local' || process.env.REACT_APP_ENV === 'development') {
            return 'carejobs'
        } else if (process.env.REACT_APP_ENV === 'housingjobs') {
            return 'housingjobs'
        } else {
            return 'nursesjobs'
        }
    }
    getNoCvForm = () => {
        return (
            <>
                {this.getEnvironment() === 'carejobs' ? (
                    <Row gutter={[16, 0]}>
                        <Col xs={24} sm={24} lg={12}>
                            <Form.Item
                                className='mb-0'
                                name='addressLine1'
                                label='Your Address'
                                rules={[
                                    {
                                        required: true,
                                        message: 'This field is required.'
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                            <div className='mb-2'><Text className='sm-text'>{content.streetAddressLabel}</Text></div>
                        </Col>
                        <Col xs={24} sm={24} lg={12}>
                            <Form.Item
                                className="label-hidden mb-0"
                                name='addressLine2'
                                label={content.addressLine2Label}
                            // rules={[
                            //     {
                            //         required: true,
                            //         message: 'This field is required.'
                            //     },
                            // ]}
                            >
                                <Input />
                            </Form.Item>
                            <div className='mb-2'><Text className='sm-text'>{content.addressLine2Label}</Text></div>
                        </Col>
                        <Col xs={24} sm={24} lg={12}>
                            <Form.Item
                                className="label-hidden mb-0"
                                name='city'
                                label={content.cityLabel}
                                rules={[
                                    {
                                        required: true,
                                        message: 'This field is required.'
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                            <div className='mb-2'><Text className='sm-text'>{content.cityLabel}</Text></div>
                        </Col>
                        <Col xs={24} sm={24} lg={12}>
                            <Form.Item
                                className="label-hidden mb-0"
                                name='postcode'
                                initialValue={this.state.postCode}
                                label={content.postCodeLabel}
                                rules={[
                                    {
                                        required: true,
                                        message: 'This field is required.'
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                            <div className='mb-2'><Text className='sm-text'>{content.postCodeLabel}</Text></div>
                        </Col>
                    </Row>
                ) : null}
                {this.getEnvironment() === 'carejobs' || this.getEnvironment() === 'housingjobs' ? (
                    <>
                        <Form.Item name='experience' label={this.getEnvironment() === 'carejobs' ? content.carejobsExperienceLabel : content.experienceLabel}
                            rules={[
                                {
                                    required: true,
                                    message: 'This field is required.',
                                }
                            ]}
                        >
                            <Radio.Group onChange={(e) => { if (e.target.value === 'yes') { this.setState({ showNoOfYears: true }) } else { this.setState({ showNoOfYears: false }) } }}>
                                <Radio value={"yes"}>Yes</Radio>
                                <Radio value={"no"}>No</Radio>
                            </Radio.Group>
                        </Form.Item>
                        {this.state.showNoOfYears ? (
                            <Form.Item name='noYearsExperience' label={content.noYearsExperienceLabel}
                                rules={[
                                    {
                                        required: true,
                                        message: 'This field is required.',
                                    }
                                ]}
                            >
                                <InputNumber min={1} />
                            </Form.Item>
                        ) : null}
                    </>
                ) :
                    (
                        <Row gutter={[16, 0]}>
                            <Col xs={24} sm={24} lg={12}>
                                <Form.Item
                                    name='nursesPin'
                                    label={content.nursesPinLabel}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'This field is required.'
                                        },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} lg={12}>
                                <Form.Item
                                    name='band'
                                    label={content.bandLabel}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'This field is required.',
                                        }
                                    ]}
                                >
                                    <Select
                                        placeholder="Select band"
                                        options={[
                                            {
                                                value: 'Band 5',
                                                label: 'Band 5',
                                            },
                                            {
                                                value: 'Band 6',
                                                label: 'Band 6',
                                            },
                                            {
                                                value: 'Band 7',
                                                label: 'Band 7',
                                            },
                                            {
                                                value: 'Band 8a',
                                                label: 'Band 8a',
                                            },
                                            {
                                                value: 'Band 8b',
                                                label: 'Band 8b',
                                            },
                                            {
                                                value: 'Band 8c',
                                                label: 'Band 8c',
                                            },
                                            {
                                                value: 'Band 8d',
                                                label: 'Band 8d',
                                            },
                                            {
                                                value: 'Band 9',
                                                label: 'Band 9',
                                            },
                                        ]}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    )}
                <Form.Item name='licence' label={content.licenceLabel}
                    rules={[
                        {
                            required: true,
                            message: 'This field is required.',
                        }
                    ]}
                >
                    <Radio.Group>
                        <Radio value={"yes"}>Yes</Radio>
                        <Radio value={"no"}>No</Radio>
                    </Radio.Group>
                </Form.Item>
                {/* <Form.Item name='location' label={content.locationLabel}
                    rules={[
                        {
                            required: true,
                            message: 'This field is required.',
                        }
                    ]}
                >
                    <Radio.Group>
                        <Radio value={"yes"}>Yes</Radio>
                        <Radio value={"no"}>No</Radio>
                    </Radio.Group>
                </Form.Item> */}
                <Form.Item name='workStatus' label={content.workStatusLabel}
                    rules={[
                        {
                            required: true,
                            message: 'This field is required.',
                        }
                    ]}
                >
                    <Select
                        placeholder="Select Work Status"
                        options={[
                            {
                                value: 'British Citizen',
                                label: 'British Citizen',
                            },
                            {
                                value: 'EU Leave to Remain - Work Permitted',
                                label: 'EU Leave to Remain - Work Permitted',
                            },
                            {
                                value: 'Permanent Residence Card',
                                label: 'Permanent Residence Card',
                            },
                            {
                                value: 'Spouse / Family / Dependent Visa',
                                label: 'Spouse / Family / Dependent Visa',
                            }, {
                                value: 'Student Visa - Restricted Hours',
                                label: 'Student Visa - Restricted Hours',
                            }, {
                                value: 'Tier 2 (General)',
                                label: 'Tier 2 (General)',
                            }, {
                                value: 'Tier 5 (Youth Mobility)',
                                label: 'Tier 5 (Youth Mobility)',
                            },

                        ]}
                    />
                </Form.Item>
                <Form.Item name='aboutYou' label={content.aboutYouLabel}
                    rules={[
                        {
                            required: true,
                            message: 'This field is required.',
                        }
                    ]}
                >
                    <TextArea rows={6} />
                </Form.Item>
            </>
        )
    }
    getUploadCvForm = (fileUploaded) => {
        return (
            <>
                {this.getEnvironment() === 'nursesjobs' ? (
                    <Row gutter={[16, 0]}>
                        <Col xs={24} sm={24} lg={12}>
                            <Form.Item
                                name='nursesPin'
                                label={content.nursesPinLabel}
                                rules={[
                                    {
                                        required: fileUploaded ? true : false,
                                        message: 'This field is required.'
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} lg={12}>
                            <Form.Item
                                name='band'
                                label={content.bandLabel}
                                rules={[
                                    {
                                        required: fileUploaded ? true : false,
                                        message: 'This field is required.',
                                    }
                                ]}
                            >
                                <Select
                                    placeholder="Select band"
                                    options={[
                                        {
                                            value: 'Band 5',
                                            label: 'Band 5',
                                        },
                                        {
                                            value: 'Band 6',
                                            label: 'Band 6',
                                        },
                                        {
                                            value: 'Band 7',
                                            label: 'Band 7',
                                        },
                                        {
                                            value: 'Band 8a',
                                            label: 'Band 8a',
                                        },
                                        {
                                            value: 'Band 8b',
                                            label: 'Band 8b',
                                        },
                                        {
                                            value: 'Band 8c',
                                            label: 'Band 8c',
                                        },
                                        {
                                            value: 'Band 8d',
                                            label: 'Band 8d',
                                        },
                                        {
                                            value: 'Band 9',
                                            label: 'Band 9',
                                        },
                                    ]}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                ) : null}
                {this.getEnvironment() === 'carejobs' || this.getEnvironment() === 'nursesjobs' ? (
                    <>
                        <Form.Item name='licenceCar' label={content.LicenceCarLabel}
                            rules={[
                                {
                                    required: fileUploaded ? true : false,
                                    message: 'This field is required.',
                                }
                            ]}
                        >
                            <Radio.Group >
                                <Radio value={"yes"}>Yes</Radio>
                                <Radio value={"no"}>No</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </>
                ) : null}
                {this.getEnvironment() === 'carejobs' ? (
                    <Form.Item name='criminalConvictions' label={content.criminalConvictions}
                        rules={[
                            {
                                required: fileUploaded ? true : false,
                                message: 'This field is required.',
                            }
                        ]}
                    >
                        <Radio.Group>
                            <Radio value={"yes"}>Yes</Radio>
                            <Radio value={"no"}>No</Radio>
                        </Radio.Group>
                    </Form.Item>
                ) : null}
                <Form.Item name='workStatus' label={content.workStatusLabel}
                    rules={[
                        {
                            required: fileUploaded ? true : false,
                            message: 'This field is required.',
                        }
                    ]}
                >
                    <Select
                        placeholder="Select Work Status"
                        options={[
                            {
                                value: 'British Citizen',
                                label: 'British Citizen',
                            },
                            {
                                value: 'EU Leave to Remain - Work Permitted',
                                label: 'EU Leave to Remain - Work Permitted',
                            },
                            {
                                value: 'Permanent Residence Card',
                                label: 'Permanent Residence Card',
                            },
                            {
                                value: 'Spouse / Family / Dependent Visa',
                                label: 'Spouse / Family / Dependent Visa',
                            }, {
                                value: 'Student Visa - Restricted Hours',
                                label: 'Student Visa - Restricted Hours',
                            }, {
                                value: 'Tier 2 (General)',
                                label: 'Tier 2 (General)',
                            }, {
                                value: 'Tier 5 (Youth Mobility)',
                                label: 'Tier 5 (Youth Mobility)',
                            },

                        ]}
                    />
                </Form.Item>
            </>
        )
    }
    getdiablityofCompleteApplicationButton = () => {
        let IsCV = this.state.isCV;
        let agreement = this.state.agreement;
        if ((this.state.token === '' && agreement) || (IsCV === "no") || (agreement && (IsCV === 'yes' || IsCV === '') && this.state.CvId !== '')) {
            return false;
        }
        return true;
    };
    renderApplyonCoWebsiteButton = () => {
        return (
            <Button type="primary" size="large" target="_blank" rel="noopener noreferrer" onClick={this.sendEmail} href={this.state.jobDetail.JobUrl}>Apply on Company Website</Button>
        )
    }
    render() {
        document.title = 'Details - ' + WEBSITE_NAME
        return (
            <Spin spinning={this.state.loader}>

                {this.state.showModal && <OTPModal email={this.state.email} response={this.modalResponse} />}
                <div className='sm-margins bg-secondary-theme push-content-down-header'>
                    <div className='container'>
                        <Button onClick={this.goToJobSearch}
                            //  href='/job-search'
                            className='qcont px-0 upwards-line' type='text' size='large'><Title level={4} className='text-white button-font'>{content.BackToSearch}</Title></Button>

                    </div>
                </div>

                {this.state.jobExist ? <div className='sm-margins'><div className='vc_column-inner pt-35 container'>
                    {/* JOB DETAILS */}
                    <Card
                        className='crd-header-style gray-header mb-4 xs-logo-placement'
                        bordered={false}
                        title={this.state.token === '' ? (
                            <Title level={4} className="break-word mb-4">
                                <span className='pr-2'>To apply for this job please</span>
                                <Button type='text' size='large' onClick={this.redirectToLogin} className="px-0"><p className='mb-0'><Text strong><span className='accent text-lowercase'>{content.SignIn}</span></Text></p></Button>
                                <span className='px-2'> or </span>
                                <Button type='text' size='large' onClick={() => { document.getElementById('registrationSubmitForm').scrollIntoView(); }} className="px-0"><p className='mb-0'><Text strong><span className='accent text-lowercase'>create an account.</span></Text></p></Button>
                            </Title>) : (null)}
                    >
                        {this.state.jobDetail ? (
                            <Row gutter={[24, 8]}>
                                <Col xs={{ span: 24, order: 2 }} lg={18} xl={{ span: 18, order: 1 }} xxl={18}>
                                    <Title level={3}><span className='accent'>{this.state.jobDetail.JobTitle}</span></Title>
                                    <p className='mb-0'><Text strong>{this.state.jobDetail.CompanyName}</Text></p>
                                    <p className='mb-0'>
                                        <Text strong>Location: </Text>
                                        <span>{this.state.jobDetail.City || ''}{this.state.jobDetail.City && this.state.jobDetail.State ? ", " : ''}</span>
                                        <span>{this.state.jobDetail.State || ''}{(this.state.jobDetail.City || this.state.jobDetail.State) && this.state.jobDetail.Postcode ? ", " : ''}</span>
                                        <span>{CommonFunctionalities.PostCodeText(this.state.jobDetail.Postcode) || ''}</span>
                                        {/* {this.state.jobDetail.Country} */}
                                    </p>
                                    <p className='mb-0'><Text strong>Salary: {this.state.jobDetail?.SalaryDescriptionHtml?.replace(/&pound;/g, '£')}</Text></p>
                                    <p className='mb-0'><Text strong>Type:</Text> {this.state.jobDetail.JobType}</p>
                                </Col>
                                <Col xs={{ span: 24, order: 1 }} lg={6} xl={{ span: 6, order: 2 }} xxl={6} className='job-logo-placement'>
                                    <div className="bg-white border-solid company-logo">
                                        <Image
                                            width={200}
                                            className='p-2'
                                            src={this.state.jobDetail.JobLogo}
                                            preview={false}
                                        />
                                        <div>
                                            {this.state.jobDetail.EmployerPage ? (
                                                <div className='mt-3 mx-1'>
                                                    <Link href={`employer-details?id=${this.state.jobDetail.BuyerId}`} >
                                                        {/* target="_blank" */}
                                                        <small><u>What is it like to work here?</u></small>
                                                    </Link>
                                                </div>
                                            ) : null}
                                        </div>
                                    </div>
                                </Col>
                                <Col xs={{ span: 24, order: 3 }} lg={24} xl={{ span: 24, order: 3 }} xxl={24}>
                                    {this.state.token !== '' && this.state.jobDetail.ClickPost ?
                                        <div className='text-right'>{this.renderApplyonCoWebsiteButton()}</div> : (
                                            <>
                                                <Divider />
                                                <div className='text-right'>
                                                    <Button type="primary" size="large" className='hover-outline' onClick={() => { document.getElementById('registrationSubmitForm').scrollIntoView(); }}>{content.ApplyNow}</Button>
                                                </div>
                                                <Divider />
                                            </>
                                        )}
                                    <p className='text-uppercase'><Text strong>{content.AboutRole}</Text></p>
                                    <div className='f-20' style={{ wordBreak: 'break-word' }} dangerouslySetInnerHTML={{ __html: this.state.jobDetail.JobDescription }}></div>
                                    {/* {this.state.token !== ''  && this.state.jobDetail.ClickPost?null:(
                                        <Button type="primary" size="large" className='hover-outline' onClick={()=>{document.getElementById('registrationSubmitForm').scrollIntoView();}}>{content.ApplyNow}</Button>
                                    )} */}
                                </Col>
                            </Row>
                        ) : null}
                        <div id='registrationSubmitForm' />
                    </Card>

                    <div>
                        <Card
                            className='crd-header-style gray-header'
                            bordered={false}
                            title={this.state.token === '' ? (<Title level={4} className="break-word">
                                To apply for this job please
                                <Button onClick={this.redirectToLogin} type='text' className="px-2"><p className='mb-0'><Text strong><span className='accent text-lowercase'>sign in</span></Text></p></Button>
                                or create an account below.
                            </Title>) :
                                <Title level={2} className="break-word">
                                    <span className='accent header-text'> Apply for Night Care Assistant </span>
                                </Title>}
                        >
                            {/* APPLY JOB FORM */}

                            <Form
                                ref={this.formRef}
                                onFinish={this.btnSubmit}
                                layout="vertical"
                                requiredMark={false}
                                name='registerUploadForm'
                                className='form-group-options'
                            >
                                {this.state.token === '' ?
                                    <>
                                        <Row gutter={[16, 0]}>
                                            <Col xs={24} sm={24} lg={12}>
                                                <Form.Item
                                                    className='mb-0'
                                                    label="Name"
                                                    name='firstname'
                                                    rules={[{ required: true, message: 'First name is required' }]}
                                                >
                                                    <Input />
                                                </Form.Item>
                                                <div className='mb-2'><Text type="secondary">First name</Text></div>
                                            </Col>
                                            <Col xs={24} sm={24} lg={12}>
                                                <Form.Item
                                                    label="lastname"
                                                    name='lastname'
                                                    rules={[{ required: true, message: 'Last name is required' }]}
                                                    className="label-hidden mb-0"
                                                >
                                                    <Input />
                                                </Form.Item>
                                                <div className='mb-2'><Text type="secondary">Last name</Text></div>
                                            </Col>
                                            <Col xs={24} sm={24} lg={12}>
                                                <Form.Item
                                                    name='email'
                                                    label={content.Email}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Enter your Email'
                                                        },
                                                        {
                                                            type: 'email',
                                                            message: 'Invalid email'
                                                        }
                                                    ]}
                                                >
                                                    <Input />
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} sm={24} lg={12}>
                                                <Form.Item
                                                    name='phonenumber'
                                                    label={content.Phone}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Enter your Phone number',
                                                        },
                                                        {
                                                            pattern: '^[- +()0-9]{10,}$',
                                                            message: 'Invalid Phone Number'
                                                        }
                                                    ]}
                                                >
                                                    <Input />
                                                </Form.Item>
                                            </Col>
                                            {/* Postcode */}
                                            <Col xs={24} sm={24} lg={12}>
                                                <Form.Item
                                                    name='postcode'
                                                    label={content.PostCode}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Enter your Post Code'
                                                        }
                                                    ]}
                                                >
                                                    <Input />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        {/* Do you have a cv ? field */}
                                        {this.state.jobDetail.ClickPost === false ?
                                            <>
                                                <Form.Item name='isCV' label="Do you have a CV?"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'This field is required.',
                                                        }
                                                    ]}
                                                >
                                                    <Radio.Group>
                                                        <Radio value={"yes"}>Yes</Radio>
                                                        <Radio value={"no"}>No</Radio>
                                                    </Radio.Group>

                                                </Form.Item>
                                            </>
                                            : null}
                                    </>
                                    :
                                    this.state.jobDetail.ClickPost ? (
                                        <>
                                            <p>Please complete this application on the company's website:</p>
                                            {this.renderApplyonCoWebsiteButton()}
                                        </>) : null}

                                {/* Upload cv button */}
                                {this.state.jobDetail.ClickPost === false && this.state.token !== '' ?
                                    <>
                                        <Form.Item name='iHaveACV'>
                                            <span className='label-text' > I Have A CV  </span>
                                            <Radio.Group onChange={(e) => { this.setState({ isCV: e.target.value }) }} value={this.state.isCV === '' ? 'yes' : this.state.isCV}>
                                                <Radio value='yes'>Yes</Radio>
                                                <Radio value='no'>No</Radio>
                                            </Radio.Group>
                                        </Form.Item>

                                        {this.state.isCV === "yes" || this.state.isCV === '' ? (
                                            <>
                                                {this.getUploadCvForm(this.state.fileUploaded)}
                                                <Form.Item name='upload' label="Upload a CV"
                                                // rules={[
                                                //     {
                                                //         required: true,
                                                //         message: 'Upload your CV',
                                                //     }
                                                // ]}
                                                >
                                                    <Upload showUploadList={true} name='file' beforeUpload={this.checkFileType} onChange={this.fileChange} action={false} fileList={this.state.fileList}> {/* customRequest={this.uploadImage} */}
                                                        <Button className='button-font' type='primary' size='large' icon={<UploadOutlined />}>Click to Upload</Button>
                                                    </Upload>
                                                </Form.Item>
                                                <div><Text className='sm-text'>{content.MaxFile}</Text></div>
                                                <p><Text className='sm-text'>{content.FileType}</Text></p>

                                                {/* {((this.state.isCV==="yes" || this.state.isCV==="") && (this.state.cvListCount==null || this.state.cvListCount <= 0)) ?
                                            ( */}
                                                {this.state.fileUploaded ? (
                                                    <Button htmlType='submit' type="primary" size="large">Submit</Button>
                                                ) : null}
                                                {/* ):null} */}
                                                <p></p>
                                                {this.state.token === '' ? (null) : (
                                                    this.state.cvListCount == null || this.state.cvListCount > 0 ? (
                                                        <>
                                                            <Divider />
                                                            {/* <p>Or</p><p>Select from your CV list</p> */}
                                                            <CVList passCVId={this.setCvId} showCheckbox={true} getCVListCount={this.SetCVCount} ref={this.child} /></>
                                                    ) : null
                                                )}

                                            </>
                                        ) : (
                                            this.getNoCvForm()
                                        )}
                                    </>
                                    : null}
                                {/* aggrement */}
                                {(this.state.token === '' || (this.state.jobDetail.ClickPost === false && this.state.cvListCount > 0)) && this.state.isCV !== 'no' ?
                                    <>
                                        <Form.Item name='agreement' valuePropName="checked">
                                            <Checkbox onChange={(e) => this.setState({ agreement: e.target.checked })}>{content.AgreeNotifications2}</Checkbox>
                                        </Form.Item>
                                    </> : null}
                                {/* {this.state.token === '' || (this.state.jobDetail.ClickPost === false) || this.state.isCV==="no"?
                                        <Form.Item name='submit'>
                                            <Button disabled={this.state.agreement || this.state.isCV==="no" ? false : true} htmlType='submit' type="primary" size="large">{this.state.cvListCount > 0 || this.state.isCV==="no" ?content.CompleteApplication : content.Register}</Button>
                                        </Form.Item>
                                    :null} */}
                                {this.state.token === '' || (this.state.jobDetail.ClickPost === false && this.state.cvListCount > 0) || this.state.isCV === "no" ?
                                    <Form.Item name='submit'>
                                        <Button disabled={this.getdiablityofCompleteApplicationButton()} htmlType='submit' type="primary" size="large" icon={this.state.cvListCount > 0 || this.state.isCV === "no" ? <UploadOutlined /> : false}>{this.state.cvListCount > 0 || this.state.isCV === "no" ? content.CompleteApplication : content.Register}</Button>
                                    </Form.Item>
                                    : null}
                            </Form>
                        </Card>
                    </div>
                </div></div> : <Empty description={<p>{content.noJobLabel}</p>} />}

            </Spin>
        )
    }
}
export default withRouter(JobDetail)
